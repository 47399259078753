.home {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    padding: 30px 0;
}

.homeImage {
    height: 500px;
    width: 500px;
}

.home-text {
    width: 350px;
    height: 160px;
}

.typewriter {
    font-size: 24px;
    margin-top: 5px;
}

  @media (max-width: 960px) {
    .home {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .typewriter {
        margin: 20px;
    }

    .homeImage-container {
        margin-top: 50px;
    }

  }