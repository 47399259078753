.navBar {
    height: 80px;
    display: flex;
    align-items: center;
    background-image: linear-gradient(to left, rgb(5, 8, 40), rgb(1, 4, 39));
  }
  
  .nav-list {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    list-style: none;
  }
  
  .nav-list li {
    margin-right: 70px;
  }

  .nav-list li:last-child {
    margin-right: 0;
  }

  .symbol {
    margin-right: 3px;
    display: inline-block;
    vertical-align: middle;
}

  .nav-list li a {
    font-size: 20px;
    font-weight: bold;
    color: white;
    text-decoration: none;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 1;
  }

  .nav-list li a::after {
    content: "";
    position: relative;
    display: block;
    height: 5px;
    width: 0;
    border-radius: 16px;
    background: #6e00fd;
    bottom: 1px;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease-out 0s;
  }
  
  .nav-list li a:hover::after {
    width: 100%;
  }
  
  .nav-list li:nth-child(4) {
    margin-left: auto;
  }

  .outlet {
    flex-grow: 1;
  }

  @media (max-width: 700px) {
    .nav-list {
      justify-content: center;
    } 
    .nav-list li:nth-child(4) {
      margin: 0px;
    }
  }