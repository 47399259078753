@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");

* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgb(5, 8, 40), rgb(1, 4, 39)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(5, 8, 40, 0.87), rgba(1, 4, 39, 0.925)
  );
}

.purple {
  color: #7500fa;
}

body {
  min-height: 100vh;
  color: white;
  font-family: "Raleway", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: var(--image-gradient), url(./home-bg.jpg);
  background-position: top center;
  background-repeat: repeat;
}

.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.loading-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  flex: 1;
}


