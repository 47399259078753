.centered-header {
    text-align: center;
  }

  .projects-heading {
    margin: 20px 0px 15px 0px;
  }
  
  .projects-grid {
    display: grid;
    gap: 50px;
    grid-template-columns: auto auto auto;
    width: 70%;
    margin: 50px auto 0px;
  }
  
  .project-container {
    display: flex;
    flex-direction: column;
    text-align: justify;
    line-height: 1.5;
    padding: 20px;
    box-shadow: 0 4px 5px 3px rgb(72, 0, 155);
    transition: all 0.5s ease 0s;
  }

  .project-container h2 {
    text-align: center;
  }
  
  .project-container:hover {
    transform: scale(1.03);
    box-shadow: 0 8px 10px 6px rgba(72, 0, 155, 1);
  }

  .project-container img {
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: 1200px) {
    .projects-grid {
        width: 90%;
    }
  }

  @media (max-width: 900px) {
    .projects-grid {
        grid-template-columns: auto auto;
    }
  }

  @media (max-width: 700px) {
    .projects-grid {
        grid-template-columns: auto;
    }
  }