.project {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.project-text {
    flex-grow: 1;
}

button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: #7500fa;
    font-weight: bold;
    color: white;
    cursor: pointer;
  }
  
  .github {
    transform: scale(1.5);
    margin-right: 10px;
  }

  .project-header {
    margin: 30px 0px 5px 0px;
  }

  .project-image {
    width: 100%;
    height: 200px;
    border-radius: 3px;
  }