.findme-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  }

  .findme-container h3 {
    margin-bottom: 7px;
  }

  .social-icons-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 114px;
  }
  
  .contact-social-icons {
    width: 395px;
    height: 95px;
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: auto 0px;
  }

  .contact-social-icons li a {
    display: inline-block;
    font-size: 2em;
    font-weight: bold; 
    color: #e6ff01;
    padding: 20px;
    background: rgba(110, 110, 110, 0.972);
    border-radius: 50%;
    margin: 12px 9px 0px 9px;
    transition: 0.5s;
  }

  .contact-social-icons li a:hover {
    border: 3px solid #e6ff01;
    color: #e6ff01;
    box-shadow: 0 0 15px #e6ff01;
    /* margin: 6px 9px 3px 9px; */
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form input {
    height: 40px;
    width: 100%;
    padding: 20px 7px;
    border: 1px solid #7500fa;
    border-radius: 7px;
    background-color: inherit;
    color: white;
    font-family: inherit;
    font-size: 1rem;
  }

  .form input:hover {
    box-shadow: 0 0 9px #7500fa;
  }

  .form input:focus {
    outline: none;
  }

  textarea {
    background-color: inherit;
    border: 1px solid #7500fa;
    height: 140px;
    border-radius: 7px;
    padding: 7px 7px;
    color: white;
    resize: none;
    font-family: inherit;
    font-size: 1rem;
  }

  textarea:hover {
    box-shadow: 0 0 9px #7500fa;
  }

  textarea:focus {
    outline: none;
  }

  #formBtn {
    background-color: #7500fa;
    color: white;
    width: 100px;
    padding: 0px;
    cursor: pointer;
  }

  #formBtn:hover {
    background-color: #6005c8;
    color: white;
    width: 100px;
    padding: 0px;
    cursor: pointer;
  }

  #formBtn:active {
    transform: scale(0.95);
  }