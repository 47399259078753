.footer {
    display: flex;
    justify-content: space-between;
    padding: 30px 90px;
    margin-top: 40px;
    background-image: linear-gradient(to left, rgb(5, 8, 40), rgb(1, 4, 39));
  }
  
  .footer-icons {
    transform: scale(1.5);
    margin-right: 20px;
    color: white;
  }
  
  .footer-icons:hover {
    color: #7500fa;
  }

@media (max-width: 960px) {
    .footer {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer p {
        margin-bottom: 20px;
    }


}