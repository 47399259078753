.about {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 50px auto;
    line-height: 1.6;
  }

  .about-heading {
    text-align: center;
  }
  
.about-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 100px;
}

.about-image-container img {
    transform: scale(1.5);
}

.aboutImage {
    height: 200px;
    width: 200px;
}

@media (max-width: 960px) {
    .about {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90%;
        margin: 50px auto;
        font-family: Arial, sans-serif;
        line-height: 1.6;
    }

    .about-image-container {
        margin: 100px 0px 0px 0px;
    }
}