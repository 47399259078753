.contact-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

.contact-container {
    width: 70%;
    display: flex;
    justify-content: space-between;
    margin: 40px 0px 15px 0px;
  }

  .oscarImage {
    height: 250px;
    width: 250px;
    margin-top: 40px;
    border-radius: 50%;
    object-fit: cover;
  }

  .contact-headings-container {
    font-size: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 1250px) {
    .contact-container {
        width: 80%;
    }
  }

  @media (max-width: 1000px) {
    .contact-container {
        width: 90%;
    }
  }

  @media (max-width: 850px) {
    .contact-container {
        flex-direction: column;
        align-items: center;
    }

    .oscarImage {
        margin: 30px 0px 20px 0px;
    }
  }